import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="header">
        <a className="btn" href="#price">
          Contattaci
        </a>
      </div>
      <div className="splash">
        <div className="splashInner">
          <div>
            <h1>Outsafe</h1>
            <p className="big description">
              <strong>Outsafe</strong> e’ una applicazione web che consente di
              tracciare in maniera semplice e veloce i dati forniti su base
              volontaria dagli utenti aderendo alle linee guida della normativa
              Federale sul COVID.
              <br />
              <br />
              <a className="btn" href="#how">
                Come funziona?
              </a>
            </p>
          </div>
          <div />
        </div>
        <img src={require("./assets/hands2.png")} className="hands" />
      </div>

      <div className="sectionHow" id="how">
        <div className="sectionHowInner">
          <div>
            <h1>
              Come
              <br />
              funziona?
            </h1>
            <p className="big description">
              <a href="https://demo.outsafe.ch" target="_blank">
                <img src={require("./assets/qrcode.png")} />
              </a>
              <br />
              <br />
              Inquadrando con la camera dello smartphone l’apposito codice QR
              l’utente accede alla scheda di registrazione dedicata, dove
              compilare ed inoltrare i propri dati.
              <br />
              <br />I dati vengono registrati in un database sicuro, sempre
              disponibili qualora fossero richiesti dalle autorita’ competenti.
            </p>
          </div>
          <div>
            <div className="appPhone">
              <img src={require("./assets/app-phone.png")} className=" form" />
              <img
                src={require("./assets/app-phone-success.png")}
                className=" success"
              />
            </div>
          </div>
        </div>
        <img src={require("./assets/ppllong.png")} className="ppllong" />
      </div>

      <div className="sectionNow" id="now">
        <div className="sectionNowInner">
          <div>
            <h1>
              Levati il
              <br />
              pensiero!
            </h1>
            <p className="big description">
              Contattaci e ricevi la tua app <strong>Outsafe </strong>
              personalizzata con i colori e il logo della tua attività per
              consentire ai tuoi clienti una registrazione facile e sicura,
              senza impegnare la tua attività con moduli che sprecano solo tempo
              e carta!
              <br />
              <br />
              <a className="btn" href="mailto:outsafe@badbyte.ch">
                Contattaci
              </a>
            </p>
          </div>
          <div />
        </div>
      </div>

      <div className="sectionPrice" id="price">
        <div className="sectionPriceInner">
          <div>
            <h1 className="small">
              La tranquillità al costo di un cappuccino al giorno
            </h1>
            <br />
            <br />
            <p className="tierTable">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <p className="price">300.- CHF</p>
                      <p>Abbonamento trimestrale</p>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      Outsafe personalizzata con
                      <br />
                      colori e logo della tua azienda
                    </td>
                  </tr>
                  <tr>
                    <td>Hosting</td>
                  </tr>
                  <tr>
                    <td>Database sicuro dedicato</td>
                  </tr>
                  <tr>
                    <td>Certificato di cifratura SSL</td>
                  </tr>
                  <tr>
                    <td>
                      Pannello on-demand
                      <br />
                      registrazioni degli ultimi 14 giorni
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <a
                style={{ fontSize: "1.2rem", padding: "1rem" }}
                className="btn"
                href="mailto:outsafe@badbyte.ch"
              >
                Contattaci
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footerInner">
          <p>
            <img src={require("./assets/beh.svg")} className="beh" />
            <h3 style={{ margin: 0 }}>BADBYTE SAGL</h3>
            Piazza dell'indipendenza 11
            <br />
            6900 Lugano
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
